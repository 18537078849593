@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400&subset=latin,latin-ext");
.NavbarMenu {
    padding-left: 1rem !important;
    padding-right: 2rem !important;
    background: #fff;
    transition: all 0.4s ease 0s !important;
    transform: translate(0,-10);

}
.NavbarMenu .navbar-brand img {
  height: 60px;
  transition: all 0.4s ease 0s !important;
}
.NavbarMenu .navbar-brand1 img {
  height: 58px;
  transition: all 0.4s ease 0s !important;
}
.NavbarMenu-fixed{
  position: fixed !important;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
}
.NavbarMenu .navbar-nav .nav-link{
    font-weight: 700;
    margin-right: 28px;
    letter-spacing: 2px;
    font-family: Roboto Condensed;
    text-transform: capitalize;
    color: #000 !important;
    transition: 0.4s ease-in-out;

  
    
}
.NavbarMenu .navbar-toggler{
  border: none !important;
}
.NavbarMenu .navbar-toggler:focus{
  box-shadow: none !important;
  outline: none;
}
.NavbarMenu .navbar-nav .nav-link:hover{
  color: #ff7a00 !important;

}
.NavbarMenu .navbar-nav .nav-link::before{
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #ff7a00;
  margin-top: 4%;
  margin-left: 24px;
  transition: 0.4s ease-in-out;
  border-radius: 3px;
  opacity: 0;

}
.NavbarMenu .navbar-nav .about::before{
  margin-left: 35px;
}
.NavbarMenu .navbar-nav .service::before{
  margin-left: 36px;
}
.NavbarMenu .navbar-nav .contact::before{
  margin-left: 37px;
}
.NavbarMenu .navbar-nav  .gallery::before{
  margin-left: 42px;
}
@media(max-width:768px){
  .NavbarMenu .navbar-nav .nav-link::before{
    display: none;
  }
}
.NavbarMenu .navbar-nav .nav-link:hover::before{
  margin-top: 2%;
  opacity: 1;
 
  
}
.NavbarMenu .navbar-nav .active {
  color: #ff7a00 !important;
}

.NavbarMenu .navbar-nav .btn-book {
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    background-color: coral;
    text-transform: capitalize;
    /* background-color: - add your own background-color */
    font-family: "Roboto", sans-serif;
    /* put your font-family */
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    overflow: hidden;
}
    /* effect-1 styles */
.effect.effect-1 {
    transition: all 0.2s linear 0s;
  }
  .btn-book.effect-1:before {
    content: "";
    font-family: FontAwesome;
    font-size: 14px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
  }
  .btn-book.effect-1:hover {
    text-indent: 10px;
  }
  .btn-book.effect-1:hover:before {
    
    text-indent: 0px;
  }
.book-apnt-btn {
  background-color: #ff7a00 !important;
  text-decoration: none;
  letter-spacing: 2px;
  line-height: 45px;
  color: rgb(236, 230, 230) !important;
  border: 2px solid #fff;
  padding: 0 42px;
  position: relative;
  text-shadow: none !important;
  font-weight: 500 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  transition: opacity 0.3s linear 0s;
}
.book-apnt-btn::before {
  width: 0 !important;
  height: 0 !important;
}
.book-apnt-btn:hover{
  padding-right: 23px !important;
  background-color: #ff7a00 !important;
  color: #fff !important;
}
.book-apnt-btn svg {
  width: 18px;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -9px;
  line-height: 18px;
  opacity: 0;
  color:#fff !important;
  font-weight: 600 !important;
  transition: all 0.2s ease-out 0s;
}
.book-apnt-btn span:hover{
  left: -18px;
}
.book-apnt-btn:hover svg {
  opacity: 1;
  right: 5px;
}
.styles-module_whatsappButton__IWx9V   {
  z-index: 1000 !important;
}
.styles-module_chatHeader__23UA6 .styles-module_avatar__17BL2 img{
  background:#fff !important ;
}
.styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE {
  z-index: 1000 !important;
}