.bgground {
  float: left;
  padding-top: 15px;
 
}
.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
}
.breadcrumbs li:first-child {
  font-size: 19px;
}
.breadcrumbs li {
  margin: 0;
  padding: 0;
  float: left;
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 20px;
  color: rgb(250, 126, 10);
}

.breadcrumbs li a {
  display: block;
  padding: 0 40px 0 0px;
  color: rgb(51, 50, 50);
  text-decoration: none;
  height: 20px;
  position: relative;
  perspective: 700px;
}

.breadcrumbs li a:after {
  content: "";
  width: 16px;
  height: 16px;
  border-color: rgb(61, 60, 60);
  border-style: solid;
  border-width: 1px 1px 0 0;

  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;

  position: absolute;
  right: 20px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotateZ(45deg) skew(10deg, 10deg);
  -moz-transform: rotateZ(45deg) skew(10deg, 10deg);
  -ms-transform: rotateZ(45deg) skew(10deg, 10deg);
  transform: rotateZ(45deg) skew(10deg, 10deg);
}

.breadcrumbs li a:hover:after {
  right: 15px;
  -webkit-transform: rotateZ(45deg) skew(-10deg, -10deg);
  -moz-transform: rotateZ(45deg) skew(-10deg, -10deg);
  -ms-transform: rotateZ(45deg) skew(-10deg, -10deg);
  transform: rotateZ(45deg) skew(-10deg, -10deg);
}
.heading {
    background-color: #fff1e3;  
    padding-top: 3rem;
    padding-bottom: 8rem;
    text-align: center;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: 35%;
}
.heading h1::after{
    background-color: #ff7a00;
    bottom: -10px;
    height: 3px;
    left: 42%;
    width: 17%;
    position: relative;
    content: "";
    display: block;
}
.heading h1 {
    font-weight: 700;
    text-shadow: 0px 0px 1.5px rgb(3, 3, 90) ;
}

.contactus-section .form-col .card h3{
    text-shadow: 0px 0px 1.5px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 30px;
}
.contactus-section .card-col .text-title{
    text-shadow: 0px 0px 1.5px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}
.contactus-section .card-col .text-head{
    text-shadow: 0px 0px 1px rgb(238, 71, 5) ;
    font-weight: 600;
    color: #f0af2d;
    margin-bottom: 10px;
    text-align: center;
}
.contactus-section .form-col .card {
    border:  none !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: -7em 0em 0em 0em;
    padding: 3em 3em 3em 3em;

}
.contactus-section .form-col .card .row .col-sm-6 .form-control {
    color: #54595F;;
    background-color: #F4F7FB;;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #F4F7FB;;
    border-radius: 30px 30px 30px 30px;
   border-radius: 50px !important;
    font-weight: normal;
    transition: all ease-in-out 0.4s;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;

  }
  .contactus-section .form-col .card .row .form-control {
    color: #54595F;;
    background-color: #F4F7FB;;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #F4F7FB;;
    border-radius: 30px 30px 30px 30px;
   border-radius: 50px ;
    font-weight: normal;
    transition: all ease-in-out 0.4s;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;

  }
  .contactus-section .form-col .card .row  select{
        color: #54595F;;
    background-color: #F4F7FB;;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #F4F7FB;;
    border-radius: 30px 30px 30px 30px;
   border-radius: 50px ;
    font-weight: normal;
    transition: all ease-in-out 0.4s;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
  }
  .contactus-section .form-col .card .row textarea {

   border-radius: 10px !important;
   

  }
  .contactus-section .form-col .card .row .form-label{
    font-weight: 600 !important;
    color: black;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }
  .contactus-section .form-col .card .row textarea:focus,
  .contactus-section .form-col .card .row  select:focus,
  .contactus-section .form-col .card .row input[type="text"]:focus,
  .contactus-section .form-col .card .row  input[type="date"]:focus,
  .contactus-section .form-col .card .row input[type="month"]:focus,
  .contactus-section .form-col .card .row input[type="time"]:focus,
  .contactus-section .form-col .card .row input[type="week"]:focus,
  .contactus-section .form-col .card .row input[type="email"]:focus,
  .contactus-section .form-col .card .row input[type="tel"]:focus
  
{   
    box-shadow: 0px 0px 2px rgba(245, 119, 2, 0.9);
  outline: none !important;
  background-color: #fff !important;

  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  }
  .contactus-section .form-col .card .row .submit-btn {
    background-color: #ff7a00 ;
    color: #F4F7FB;
    width: 70%;
    font-weight: 600;
    font-size: 20px;
    border-radius: 50px;
    margin-top: 15px;
    cursor: pointer;

  }
  .contactus-section .form-col .card .row .submit-btn:hover{
transform: translate(-20px,0px);
animation-name: slide; 
animation-duration: 2s; 

animation-timing-function: linear;
  }
  @keyframes slide {
    from {transform: translate(-20px,0px);}
    to {transform: translate(0px,0px);}
  }
  .contactus-section .card-col .row .col-sm-6 .card{
    border:  none !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    /* margin: -10em 0em 0em 0em; */
    padding: 1em 1em 1em 1em;
    height: 100%;
    border-radius: 0 !important;
  }
  .contactus-section .card-col .row .col-sm-6 {
    padding: 0.8rem;
  }
  .contactus-section .card-col .row .col-sm-6 .card .card-body i {
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    font-size: 28px;
  }
  .contactus-section .card-col .row .col-sm-6 .card .card-body h2 {
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    font-size: 28px;
  }
  .contactus-section .card-col .row .col-sm-6 .card .card-body p {
    text-shadow: 0px 0px 0.2px rgb(3, 3, 90) ;
    color: #323335;
  }
  .contactus-section .card-col .row .col-sm-6 .card:hover i{
    color: #ff7a00 ;
    transition: all 0.4s ease 0s;
  }
  .contactus-section .card-col .row .col-sm-6 .card{
    transition: all 0.4s ease 0s;
    border-radius: 8px;

  }
  .contactus-section .card-col .row .col-sm-6 .card:hover{
    transform: translate(0,-15px);
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 20%);
  }
  /* banner */
  .banner {
    border-radius: 0 !important;
    background: red !important;
  }
  .banner h1 {
    text-align: center;
    color: #fff;
    text-shadow: 0 -2px 1px rgb(189, 189, 189);
  }
  
  /* Services css start */
  .services {
    border:  none !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
 
    padding: 1em 1em 1em 1em;
    height: 100%;
    background-color: #fff;
    padding-left: 3.5rem ;
    padding-right: 3.5rem ;

  }
 
  .services .row .col-first p {
    text-shadow: 0px 0px 1px rgb(238, 71, 5) ;
    font-weight: 600;
    color: #f0af2d;
    margin-bottom: 10px;
  }
  .services .row .col-first h1 {
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 30px;
   
  }
  .services .row .col-mid p{
    color: #323335;
    line-height: 25px;
  }
  .services .row .col-mid button{
    background-color: #ff7a00 ;
    color: #F4F7FB;
    font-weight: 600;
    font-size: 20px;
    border-radius: 50px;
    margin-top: 15px;
    cursor: pointer;
  }
  .services .row .col-last .opening-hours {
    background-color: #ff7a00;
    color: #fff;
  }
  .services .row .col-last .opening-hours  i {
    background-color: rgb(244, 248, 7);
    height: 30px;
    width: 30px;
    font-size: 20px;
    border-radius: 50px;
    padding-left: 5px;
    padding-top: 5px;
    box-shadow: 0px 0px 2px rgb(70, 69, 69);
    margin-bottom: 15px;
}
.service-section-card h1{
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 30px;
}
.service-section-card p{
    text-shadow: 0px 0px 0.2px rgb(3, 3, 90) ;
    color: #323335;
}
.service-section-card .row .col-md-4 .card {
    border:  none !important;
    
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    /* margin: -10em 0em 0em 0em; */
    padding: 1em 1em 1em 1em;
   
}
.service-section-card .row .col-md-4 .card:hover{
    transform: translate(0,-15px);
    transition: all 0.4s ease 0s;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 20%);
  }
.service-section-card .row .col-md-4 .card img
{
    background: #ff7a00;
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 50px;
}
.service-section-card .row .col-md-4 .card button{
    border-radius: 50px;
}
.service-section-card .row .col-md-4{
    padding: 1rem;
}
.service-section-card{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
/* ABOUT CLICNIC CSSS START */
.about-clinic {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.about-clinic .first-col {
    padding-left: 0 !important;
    padding-top: 3rem;
    background-size: cover;
    background-position: -30%;
   
}
.card-col-about .box{
  height: 100px;
  width: 100px;
  z-index: -1;
  margin-top: 10rem;
  left: -50px;
  background: #ff8a3c;
  position: absolute;

  border-radius: 50%;
  transform: scale(1.1);
}
.card-col-about .box1{
  height: 200px;
  width: 200px;
  z-index: -1;
  margin-top: 7rem;
  left: -100px;
  background: #ff8a3c;
  position: absolute;
 
  border-radius: 50%;
  opacity: 0.4;
  transform: scale(1.1);
}
.card-col-about .box2{
  height: 300px;
  width: 300px;
  z-index: -1;
  margin-top: 4rem;
  left: -150px;
  background: #ff8a3c;
  position: absolute;

  border-radius: 50%;
  opacity: 0.2;
  transform: scale(1.1);
}
.card-col-about .box4{
  height: 400px;
  width: 400px;
  z-index: -1;
  margin-top: 1rem;
  left: -200px;
  background: #ff8a3c;
  position: absolute;
  transform: scale(1.1);

  border-radius: 50%;
  opacity: 0.1;
}
.card-col-about {
  background: #ffeadd;
  position: relative;
  z-index: -1;
  margin-top: -10em !important;

}
.about-clinic .first-col .card {
  border-radius: 0 !important;
  padding: 0.5em;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; 
}
.about-clinic .second-col h1{
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.about-clinic .second-col .heading-title{
    text-shadow: 0px 0px 1px rgb(238, 71, 5) ;
    font-weight: 600;
    color: #f0af2d;
}
.about-health{
    margin-top: 5rem;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.about-health img {
    border-radius: 20px;
}
.about-health .card {
    border:  none !important;
    
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: -15em 0em 0em 0em;
    padding: 2em 2em 2em 2em;
    width: 80%;
    margin-left: 10%;

}
.about-health .card-col img {
  margin-top: 0 !important;
}
.about-health .card .card-body .heading-title{
    text-shadow: 0px 0px 1px rgb(238, 71, 5) ;
    font-weight: 600;
    color: #f0af2d;
    text-align: center;
}
.about-health .card .card-body h1{
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}
.about-health .card .card-body p {
    
    color: #323335;
    line-height: 28px;
    text-align: center;
}

.about-health .icon i {
    color: #ff7a00;
    font-size: 34px;
    text-align: center;
}
.about-health .sentence h2 {
    text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
}
.about-health .sentence p {
    color: #54595F;
    text-shadow: 0px 0px 1px #54595F;
    text-align: left !important;
}
.doctor-section  {
    margin-top: 8rem;
}
.doctor-section .card {
    border-radius:0 !important;
    padding: 1em;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.doctor-section .row .col-md-8 .row .col-md-4 img{
    height: 400px;
   
}
.doctor-section .row .col-md-8 .row .col-md-4 {
    padding-top: 2rem;
}
.doctor-section .row .col-md-8 .row .col-md-8{
    padding-top: 2rem;
}

.doctor-section .row .col-md-8 .row .col-md-8 .lst-img{
    margin-top: -6rem;
}
.doctor-section .row .col-md-8 .row .col-md-4 .lst-img{
    margin-top: -6rem;
}
.doctor-section .row .col-md-4 img {
    bottom: 0;
    position: relative;
}
.doctor-section .row .col-md-8 .sq-box {
    width: 65%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border-bottom-right-radius: 50%;
    background-color: #ff7a00 !important;
	filter: blur(60px);
	opacity: 0.2;
	
}
.smile h2, .row .col-md-4 h2, .row .col-md-6 h2 {
  text-shadow: 0px 0px 1px rgb(3, 3, 90) ;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
}
.smile h3, .row .col-md-4 h3, .row .col-md-6 h3 {
  letter-spacing: 0.5px;
  margin-top: 10;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.smile  .row  .card img {
  height: 250px;
  border-radius: 0 !important;
}
.smile  .row .card {
  border-radius: 0 !important;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.smile  .row  .card .card-body .card-title {
  text-transform: uppercase;
  font-weight: 600;
}
.smile  .row .col-md-6 .card img {
  height: 300px;
}
.smile  .row .card {
  overflow: hidden;
  transition: all 0.4s ease 0s;

}
.smile  .row .card .cardimgbox{
  overflow: hidden;
}
.smile  .row .card img:hover{
  transform: scale(1.3);
  transition: all 0.4s ease 0s;
}
@media(max-width:768px){
  .about-health{
    padding: auto;
  }
  .about-health .card {
    margin: 0em 0em 0em 0em;
    width: 100%;
    margin-left: 0;
  }
  .contactus-section .form-col .card {
    margin-top: 0;
  }
  .about-health .card .row {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: -1em !important;
  }
  .about-health .sentence{
    margin-top: 0.5em;
  }
  .about-health .sentence h2  {
    justify-content: center;
    text-align: center;
    align-items: center;

  }
  .about-health .sentence p{
    text-align: center !important;
  }
  .card-col-about{
    margin-top: 0 !important;
  }
  .smile  .row .card img {
    height: auto;
  }
  .services .row .col-mid {
    padding-top: 0.5em !important;
    padding: 1em;
  }
  .services .row .col-mid , .col-first {
    text-align: center;
    
  }
  .about-clinic .second-col {
    text-align: center;
  }
}
@media(max-width:540px){
  .heading {
    padding-bottom: 3rem;
  }
}
.react-photo-gallery--gallery img:hover {
transform: scale(1.1);
transition: all 0.4s ease 0s;
}
.ball {
  height: 100px;
  width: 100px;
  background-color: #ff7a00;
  position: absolute;
  z-index: -1;
  border-radius: 50px;
  left: 40px;
  opacity: 0.8;
}
.ball1 {
  height: 300px;
  width: 300px;
  background-color: #ff7a00;
  position: absolute;
  z-index: -1;
  border-radius: 150px;
  right: 0;
  opacity: 0.6;
  bottom: 0;
  top:800px
}
.ball2 {
  height: 200px;
  width: 200px;
  background-color: #ff7a00;
  position: absolute;
  z-index: -1;
  border-radius: 100px;
  right: 0;
  opacity: 0.6;
}
.ball3 {
  height: 400px;
  width: 400px;
  background-color: #ff7a00;
  position: absolute;
  z-index: -1;
  border-radius: 200px;
  left: 0;
  opacity: 0.5;

}