@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400&subset=latin,latin-ext");
.item-container{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.item-container h2 {
    font-style: normal;
    font-weight: 700;
    text-shadow: 0 0 1px blue;
 
}
.item-container p , ul li {
    font-family: Roboto Condensed;
}
.item-container p span {
    font-weight: 600;
}
.item-container p {
    margin-top: 0.5em;
}
.item-container h3{
    font-style: normal;
    font-weight: 700;
    text-shadow: 0 0 1px rgb(16, 16, 112);
    font-size: 23px;
    vertical-align: baseline;
    margin-top: 1em;
 
}
.item-container .img-box {
    justify-content: center;
    align-items: center;
    text-align: center;
}
.item-container .row .col-md-4 .list-group {
    border: none !important;
}
.item-container .row .col-md-4 .list-group .list-group-item{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-weight: 600;
    text-transform: capitalize;
    padding-top: 2em;
    transition: all 0.4s ease-in-out;
    
}
.item-container .row .col-md-4 .list-group .list-group-item:hover {
    color: #ff7a00 !important;
    transform: translateX(3px)

}
.item-container .row .col-md-4 .list-group .activate{
    color: #ff7a00 !important;
    transform: translateX(3px)
}
.item-container .row .col-md-4 input[type="search"]{
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}
.item-container .row .col-md-4 input[type="search"]:focus{
    outline: none !important;
   
}
.item-container .denture {
    text-align: center;
}
@media(max-width:768px){
    .item-container .row .col-md-4 .list-group {
        margin-top: 1em;
    }


}