.speech-bubble {

  color: #333;
  display: inline-block;
	font-size: 14px;
  margin-bottom: 1em;
	padding: 1em 1em 1em 1em;
	position: relative;
	text-align: center;

		min-width: 7em;
    z-index: 1;
    height: 200px !important;
    border: none !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 15%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.speech-bubble:after {
	border: 1em solid transparent;
	border-top-color: rgb(255, 251, 251);
	content: '';
	margin-left: -1em;
	position: absolute;
		top: 100%;
		left: 20%;
	width: 0;
	height: 0;
}
.review-container{
  background: #fff3e8;
  z-index: -999;

}
.review-container h1{
  font-weight: 700;
  text-shadow: 0px 0px 1px blue;
}
.review {
  padding:2em 2em 2em 2em;
}
.review .name{
  font-weight: 600;
  text-shadow: 0px 0px 1px blue;
  margin-top: 1em;
}
.review img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 3em;
  box-shadow: 0 0 0 5px rgba(rgb(231, 13, 13),.8) ;
  margin-top: 1em;
}

.speech-bubble.large {
	font-size: 2em;
	padding: 0 1em;
		min-width: 4em;
}

.speech-bubble.rounded {
	border-radius: .4em;
}

.speech-bubble.blue {
	background: #ddf;
	color: #334;
}
.speech-bubble.blue:after {
	border-top-color: #ddf;
}
.bx {
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: #ff7a00;
  left: 45%;
  top:546%;
  border-radius: 50%;
  z-index: -1;

}
.bx1 {
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: #ec8523;
  left: 37%;
  top:538%;
  opacity: 0.7;
  border-radius: 50%;
  z-index: -1;

}
.bx2 {
  position: absolute;
  width: 600px;
  height: 600px;
  background-color: #f39e4e;
  left: 33%;
  border-radius: 50%;
  top:530%;
  opacity: 0.6;
  z-index: -1;

}