.header {
    padding: auto;
    background: #f5f5f5 !important;
   
    
}
.header .navbar-brand  {
    background-color: #ff7a00 !important;
    color:#fff !important;
    padding-left: 10px;
    padding-right: 10px;
    font-size: medium;
    font-weight: 700;
}
.header .navbar-brand:hover{
    transition: all 0.3s ease-out;
    transform: scale(1.05);
}

.header  .navbar-nav .nav-link span:hover{
    color: rgb(32, 215, 19);
}


.header .navbar-nav .nav-link .fa-facebook-square{
    color: rgb(72, 72, 255);
}
.header .navbar-nav .nav-link .fa-instagram{
    color: rgb(248, 68, 128);
}
.header .navbar-nav .nav-link .fa-twitter{
 color: rgb(108, 189, 255)
}
.header .navbar-nav .nav-link  .fa-youtube{
    color: red;
}
@media (max-width:455px){
    .header  .navbar-nav .nav-link {
        font-size: 10px !important;
    }
}