.services {
    margin-top: 150px ;
    padding-top: 8em;
    background: #ffe3d656 !important;
    overflow-x: hidden;
    
}
.services h1 {
    font-weight: 700;
    letter-spacing: 1px;
}
.services .thead{
    text-shadow: 0px 0px 1px black;
    font-size: 20px;
    color: rgb(39, 34, 34);
    font-weight: 500;
    text-transform: uppercase;
}
.services .thead::after{
    display: inline-block;
    margin: 0 0 8px 10px;
    height: 3px;
    content: " ";
    text-shadow: none;
    background-color: #f57033;
    width: 50px;
}
.services .thead::before{
    display: inline-block;
    margin: 0 10px 8px 0;
    height: 3px;
    content: " ";
    text-shadow: none;
    background-color: #f57033;
    width: 50px;
}
.services .row .col-md-4 .card h3 {
    font-size: 25px;
    text-transform: capitalize;
    text-shadow: 0px 0px 1px blue;
    font-family: Roboto 'Condensed';
   
}
.services .row .col-md-4 .card p {
    color: rgb(107, 107, 107);
}
.services .row .col-md-4 .card {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 15%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 1em 1em 1em 1em;
    transition: all 0.4s ease-in-out;
 
}
.services .row .col-md-4 .card:hover{
    transform: translate(0,-15px);
    transition: all 0.4s ease 0s;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 28%);
}
.services img {
    height: 65px;
    width: 65px;
    border-radius: 50px;
    padding: 15px;
    background: #ff7a00;
    box-shadow: 0 0 0 3px #f8b86e;
    transition: 0.3s ease-in-out;

}
.services .row .col-md-4 .card:hover img {
    transform: scale(1.08);
}
.services .row .col-md-4 {
    padding: 2rem !important;
}
.modal-content{
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px !important; 
} 
.modal-header {
    border-bottom: none !important;
}
.modal-footer {
    border-top: none !important;
}
.modal-content .btn-close{
    background-color: red;
    color: black;
    transition: 0.4s ease 0s;
    border-radius: 50px;
}
.modal-content .btn-close:hover{
    transform: rotate(-145deg);
}
.circle{
    position: absolute;
    width: 400px;
    height: 400px;
    left: -200px;
    background: #ff7a00;
    margin-top: 26.5rem;
border-radius: 50%;

}
.circle-s{
    position: absolute;
    width: 600px;
    height: 600px;
    left: -300px;
    background: #ff7a00;
    margin-top: 20.5rem;
border-radius: 50%;
opacity: 0.4;

}
.circle-m{
    position: absolute;
    width: 800px;
    height: 800px;
    left: -400px;
    background: #ff7a00;
    margin-top: 14.5rem;
border-radius: 50%;
opacity: 0.3;

}
.circle-l{
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: -500px;
    background: #ff7a00;
    margin-top: 8.5rem;
border-radius: 50%;
opacity: 0.2;

}
@media(max-width:768px){
    .services {
        padding-left:0 !important ;
        padding-right:0 !important ;
    }
}
@media(max-width:540px){
    .circle{
        position: absolute;
        width: 200px;
        height: 200px;
        left: -100px;
        background: #ff7a00;
        margin-top: 26.5rem;
    border-radius: 50%;
    
    }
    .circle-s{
        position: absolute;
        width: 300px;
        height: 300px;
        left: -150px;
        background: #ff7a00;
        margin-top: 23.5rem;
    border-radius: 50%;
    opacity: 0.4;
    
    }
    .circle-m{
        position: absolute;
        width: 400px;
        height: 400px;
        left: -200px;
        background: #ff7a00;
        margin-top: 20.5rem;
    border-radius: 50%;
    opacity: 0.3;
    
    }
    .circle-l{
        position: absolute;
        width: 500px;
        height: 500px;
        left: -250px;
        background: #ff7a00;
        margin-top: 17.5rem;
    border-radius: 50%;
    opacity: 0.2;
    
    }
}