.main-404 {
    background: rgba(250, 163, 1, 0.11);
    height: 100vh;    
}
.main-404 h1 {
    font-size: 400px;
    color: rgb(248, 162, 1);
    text-shadow: 0 5px 3px rgb(110, 110, 110);
}
.main-404 p {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
}