
.why .row .col-md-5 .head {
    color: orangered;
}
.why .why-container {
    padding-left: 4em !important;
    padding-right: 4em !important;
    padding-top: 3em !important;
    z-index: 1;
}
.why .row .col-md-5{
    position: relative;
    z-index: 1;
}
.why .row .col-md-5  h1 {
font-weight: 700;
text-shadow: 0px 0px 1px blue;
letter-spacing: 1px;
}
.why .row .col-md-7 .row .col-md-6 {
    padding: 1em;
}
.why .row .col-md-7 .row .col-md-6 .card{
    border:  none !important;
    z-index: 1;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    border-radius: 0 !important;
    padding-top: 1em;
    padding-bottom: 1em;
    height: 300px;
    transition: all 0.8s ease 0s;
}
.why .row .col-md-7 .row .col-md-6 .card:hover{
    transform: scale(1.05);
}
.why .row .col-md-7 .row .col-md-6 .card .card-title{
    text-shadow: 0px 0px 1px blue;
    letter-spacing: 1px;
    font-weight: 600;
}
.why .row .col-md-7 .row .col-md-6 .card .card-text {

    color: rgb(39, 39, 39);
}
.why .row .col-md-7 .row .col-md-6 .card svg {
 
    margin-left: 1em;
    
}
.why .box{
    width:200px;
    height: 200px;
    background: rgb(248, 58, 0);
    position: absolute;
    right: 0;

    border-bottom-left-radius: 900px;
}
.why .box1{
    width:325px;
    height: 300px;
    background: rgb(250, 138, 104);
    position: absolute;
    right: 0;
    opacity: 0.7;
    border-bottom-left-radius: 900px;
  
  
}
.why .box2{
    width:435px;
    height: 400px;
    background: rgb(253, 134, 97);
    position: absolute;
    right: 0;
    opacity: 0.6;
    border-bottom-left-radius: 900px;
}

.why {
    background: rgb(255, 242, 238);
    padding-top: 0 !important;
    
}
@media(max-width:768px){
    .why .row .col-md-7 .row .col-md-6 .card{
        height: auto;
    }
}