@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400&subset=latin,latin-ext");
.doctors{
    padding-top: 2em;
    margin-top: 0;
    margin-bottom: 0;
}
.doctors .row .col-md-5 .headt {
    color: rgb(24, 22, 20);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-family: headingfont;
    margin-top: 2rem;
    text-align: center;

}
.doctors .row .col-md-5 .headt::after{
    display: inline-block;
    margin: 0 0 4px 8px;
    height: 2px;
    content: " ";
    text-shadow: none;
    background-color: #ff8a3c;
    width: 50px;
    
}
.doctors .row .col-md-5 .headt::before{
    display: inline-block;
    margin: 0 10px 4px 0;
    height: 2px;
    content: " ";
    text-shadow: none;
    background-color: #ff8a3c;
    width: 50px;
}
@font-face {
    font-family: headingfont;
    src: url('/public/fonts/Mollen-RegularPersonalUse.otf');
}
.doctors .row .col-md-5 h1 {

    font-weight: 700;
text-shadow: 0px 0px 1px blue;
letter-spacing: 1px;
text-align: center;


}
.doctors .row .col-md-5 p {
    text-align: center;
    letter-spacing: 0px;
    color: rgb(83, 83, 83);
}
.doctors .row .col-md-5 h1 span {
    color: rgb(255, 123, 0);
}



.doctors .row .col-md-3 .doc1 {

    height: 90%;

}
.doctors .row .col-md-4 .doc2 {
    width: 100%;

}
.doctors .box{
    height: 180px;
    width: 160px;
    z-index: -1;
    background: #ff8a3c;
    position: absolute;
    filter: blur(80px);
}
.doctors .box2{
    height: 190px;
    width: 160px;
    background: #ff8a3c;
    position: absolute;
    right: 0;
    filter: blur(90px);
    z-index: -1;
     margin-top: 15%;
   
}