@font-face {
    font-family: myfont;
    src: url('/public/fonts/VanillaExtractRegular.ttf');
}
@font-face {
    font-family: mollen;
    src: url('/public/fonts/Mollen-RegularPersonalUse.otf');
}
.herocard-section {
    margin: -100px;
}
.herocard-section .row .col-md-4 {
    padding: 0 !important;
}
.herocard-section .card{
    border-radius: 0 !important;
    border: none;

}
.herocard-section .card {
    height: 330px;
}
.herocard-section .detail {
    text-align: center;
    background: #ececec ;
    margin-top: -2em ;
    color: rgb(32, 32, 32);
    border: none !important;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 15%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

}

.herocard-section .detail h1 {
    font-family: mollen;
    line-height: 100px;
    font-weight: 700;
    text-shadow: 0px 0px 1px blue;
}
.herocard-section .detail h4::after {
    content: "";
    position: absolute;
    background-color: #ff4800;
    bottom: 95px;
    left: 25%;
    width: 50%;
    height: 3px;
}
@media(max-width:412px){
    .herocard-section .detail h4::after{
        bottom: 125px;  
    }  
}
.herocard-section .detail h4 {
    font-family: myfont;
}
.herocard-section .opening-hours {
    background-color:#ff4800;
    color: #fff;
}
.herocard-section .opening-hours h1 {
    font-family: mollen;
    font-weight: 700;
}
.herocard-section .opening-hours p {
    font-family: mollen;
   
    
   
}

.herocard-section .opening-hours  i {
    background-color: #fca402;;
    height: 30px;
    width: 30px;
    font-size: 20px;
    border-radius: 50px;
    padding-left: 5px;
    padding-top: 5px;
    box-shadow: 0px 0px 2px rgb(70, 69, 69);
    margin-bottom: 15px;
}
.herocard-section .booking {
    background-color: #ff4800;
    color: #fff;
}
.herocard-section .booking .card-title{
    font-size: 25px;
}
.herocard-section .booking .row .col-sm-6 .form-control {
    background: #f9f9f9;
    
   border-radius: 50px !important;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif!important; 
    font-weight: normal;
    transition: all ease-in-out 0.4s;
    margin-bottom: 15px;

  }
 .herocard-section  .booking .row .col-sm-6 select {
    border-radius: 50px !important;
  }
 
  .herocard-section .booking .row .col-sm-6  textarea:focus,
  .herocard-section .booking .row .col-sm-6   select:focus,
  .herocard-section .booking .row .col-sm-6  input[type="text"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="date"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="month"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="time"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="week"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="email"]:focus,
  .herocard-section .booking .row .col-sm-6  input[type="tel"]:focus
  
{   
    box-shadow: none !important;
  outline: none !important;
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  }
  ::placeholder {
   font-weight: 600 !important;
 }
  [placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
 }
 .herocard-section .booking .book-btn {
    width: 100%;
    padding-bottom: 5px;
    background: transparent;
    height: 40px;
    color: #fff;
    font-weight: 600;
	border-radius: 50px;
    border: 2px solid #fff;
	-webkit-transition: background-color 0.3s, color 0.3s;
	transition: background-color 0.3s, color 0.3s;
 }
 .herocard-section .booking .book-btn:hover{
    background: #f5ce1f;
    border: none;
 }
 @media(max-width: 768px){
    .herocard-section .detail {
        margin-top: 0;
    }
    .herocard-section .card{
        height: auto;
    }
    .herocard-section .booking .book-btn{
        margin-top: 1em;
    }
    .herocard-section  .booking .card-body{
        padding-left: 3em !important;
        padding-right: 3em;
      }
 }